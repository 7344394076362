exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-request-tsx": () => import("./../../../src/pages/request.tsx" /* webpackChunkName: "component---src-pages-request-tsx" */),
  "component---src-pages-requested-tsx": () => import("./../../../src/pages/requested.tsx" /* webpackChunkName: "component---src-pages-requested-tsx" */),
  "component---src-templates-artist-tsx": () => import("./../../../src/templates/artist.tsx" /* webpackChunkName: "component---src-templates-artist-tsx" */),
  "component---src-templates-quiz-tsx": () => import("./../../../src/templates/quiz.tsx" /* webpackChunkName: "component---src-templates-quiz-tsx" */),
  "component---src-templates-song-tsx": () => import("./../../../src/templates/song.tsx" /* webpackChunkName: "component---src-templates-song-tsx" */)
}

